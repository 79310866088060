
function carrousel() {
    const carousel = document.querySelector('.carousel__lista');
    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        duration: 3,
        rewind: true,
        //skipTrack: true,
        dots: '.carousel__indicadores',
        arrows: {
            prev: '.carousel__anterior',
            next: '.carousel__siguiente'
        },
        responsive: [
            {
              // screens greater than >= 800px
              breakpoint: 800,
              settings: {
                // Set to `auto` and provide item width to adjust to viewport
                slidesToShow: 3,
                slidesToScroll: 3,
                itemWidth: 300,
                duration: 3,
                //skipTrack: true
              }
            },
            {
                // screens greater than >= 1200px
                breakpoint: 1200,
                settings: {
                  // Set to `auto` and provide item width to adjust to viewport
                  slidesToShow: 4,
                  slidesToScroll: 4,
                  itemWidth: 1000,
                  duration: 3,
                  rewind: true,
                  //skipTrack: true
                }
              }
          ]

    };

    new Glider(carousel, settings);
}

carrousel();

function prueba() {
    const coordinaciones = document.querySelectorAll(".cont-info-coor");

    coordinaciones.forEach(item => {
        item.style.transition = "all 1s";
    });
}

setTimeout(() => {
    prueba();
    document.querySelector('section[data-id="5e95552d"]').id = 'curriculo';
    document.querySelector('section[data-id="39e1c2a0"]').id = 'academico';
    document.querySelector('section[data-id="a944fa4"]').id = 'curriculom';
    document.querySelector('section[data-id="299788f"]').id = 'academicom';
    document.querySelector('section[data-id="bf66e99"]').id = 'pedagogia';
    document.querySelector('section[data-id="d335469"]').id = 'evaluacion';
    changeHref();
}, 800);

function growDiv(parent, div) {
  const parent1 = document.querySelector(parent);
  const div1 = document.querySelector(div);

  parent1.addEventListener('mouseover', function () {
    div1.style.height = '200px';
  });

  parent1.addEventListener('mouseout', function () {
    div1.style.height = '100px';
  });
}

const array = ['.curri', '.divconve', '.pedago', '.divconve2', '.acade', '.divconve3', '.tecno', '.divconve4', '.eval', '.divconve5'];

const anchoPantalla1 = document.body.clientWidth;

if(anchoPantalla1 > 1300) {
  for (let i = 0; i < array.length; i += 2) {
    growDiv(array[i], array[i+1]);
  }
}

function changeHref() {
  const anchoPantalla = document.body.clientWidth;
  const iconCurri = document.querySelector('.cont-coor-carrusel .curri a');
  const iconAcade = document.querySelector('.cont-coor-carrusel .acade a'); 

  if(anchoPantalla < 1200) {
      iconCurri.href = "#curriculom";
      iconAcade.href = "#academicom";
  }

  window.addEventListener('resize', function () {
    const anchoPantalla1 = document.body.clientWidth;

    if(anchoPantalla1 > 1200) {

      iconCurri.href = "#curriculo";
      iconAcade.href = "#academico";

    }else {

      iconCurri.href = "#curriculom";
      iconAcade.href = "#academicom";

    }
  });
}

document.querySelector('head').innerHTML += '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/glider-js@1.7.7/glider.min.css"><link rel="stylesheet" href="/assets/css/inicio.css">';